import React, { FC, useEffect } from 'react'
import classNames from 'classnames'
import styles from './Modal.module.scss'
import ReactPortal from '../../ReactPortal'
import CloseIcon from './CloseIcon'

interface Props {
  isOpen: boolean
  mobileHasFullHeight?: boolean
  handleClose: () => void
  children?: React.ReactNode
  customStyle?: string
}
const Modal: FC<Props> = ({
  isOpen,
  handleClose,
  children,
  mobileHasFullHeight,
  customStyle
}) => {
  useEffect(() => {
    if (isOpen) {
      const closeOnEscapeKey = (e: { key: string }) =>
        e.key === 'Escape' ? handleClose() : null
      document.body.addEventListener('keydown', closeOnEscapeKey)
      return () => {
        document.body.removeEventListener('keydown', closeOnEscapeKey)
      }
    }
  }, [handleClose])

  if (!isOpen) return null
  return (
    <ReactPortal wrapperId="react-portal-modal-container">
      <div
        className={classNames(
          styles.ModalWrapper,
          {
            [styles.FullHeight]: mobileHasFullHeight
          },
          customStyle ? customStyle : ''
        )}
      >
        <div className={styles.Modal}>
          <div
            role="button"
            onClick={handleClose}
            onKeyDown={handleClose}
            tabIndex={0}
            className={styles.CloseButton}
            data-testid="modal-close-icon"
          >
            <div className={styles.CloseIcon}>
              <CloseIcon />
            </div>
          </div>
          <div className={styles.ModalContent}>{children}</div>
        </div>
      </div>
    </ReactPortal>
  )
}
export default Modal
