import React, { FC } from 'react'
import { useDispatch } from 'react-redux'

import channelsActions from '../../../../redux-store/channel/channels.actions'
import DarkBackground from '../Images/dark-background.png'
import { ImageUploadOverlayButton } from '../ImageUpload/ImageUpload'

import styles from './ChannelLogo.module.scss'

interface Props {
  imageUrl: string | undefined
  canChangeImage: boolean
  channelSlug: string
}

export const ChannelLogo: FC<Props> = ({
  imageUrl,
  canChangeImage,
  channelSlug
}) => {
  const dispatch = useDispatch()

  const handleImageUploaded = (path: string) => {
    dispatch(channelsActions.updateLogoImage(path))
  }

  const image = imageUrl || DarkBackground

  return (
    <div
      data-testid="channel-image-container"
      className={styles.ChannelImageContainer}
    >
      <div
        className={styles.ChannelImage}
        style={{
          backgroundImage: `url(${image})`,
          backgroundSize: imageUrl ? 'contain' : 'cover',
          backgroundRepeat: 'no-repeat'
        }}
      />
      {canChangeImage && (
        <div className={styles.UploadIcon}>
          <ImageUploadOverlayButton
            path={`session/companies/${channelSlug}/logo`}
            onImageUploaded={handleImageUploaded}
          />
        </div>
      )}
    </div>
  )
}
