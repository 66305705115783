/* eslint-disable no-nested-ternary */
import React, { FC, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import ChannelPageContent from './ChannelPageContent'
import useAdminAccess from '../../hooks/AdminAccess'
import { channelSelector } from '../../redux-store/channel/channel.selectors'
import { isUserChannelAdminSelector } from '../../redux-store/user/user.selectors'
import { ChannelTypes } from './channelType'
import { ChannelPageRoutes } from './Routes/ChannelPageRoutes'
import NotFoundPage from '../NotFoundPage'
import useCompanyParam from '../../hooks/CompanyParam'

import styles from './index.module.scss'
import cssTheme from './channel.module.scss'
import classNames from 'classnames'

export const ChannelPage: FC = () => {
  const [viewAsUser, setViewAsUser] = useState(false)
  const adminAccess = useAdminAccess()
  const channel = useSelector(channelSelector)

  const company = useCompanyParam()
  const channelType: ChannelTypes = channel?.data?.type
  const channelSlug = channel?.data?.subdomain

  const isChannelAdmin = useSelector(isUserChannelAdminSelector)
  const isAdmin = adminAccess.isAdmin || isChannelAdmin
  const isRootPage = channel?.data?.isRootChannel

  const [isReadyToRender, setIsReadyToRender] = useState(false)
  const [changedTab, setChangedTab] = useState<any>()

  useEffect(() => {
    if (channel.data && !channel.isLoading && !channel.isError) {
      setIsReadyToRender(true)
    }
  }, [channel, company])

  const handleShowAsUserClick = () => {
    setViewAsUser(!viewAsUser)
  }

  const handleChangeTab = (destinationTab: string) => {
    setChangedTab(destinationTab)
  }
  const isScheduleAdmin = isAdmin && !viewAsUser

  return isReadyToRender ? (
    <div
      className={classNames(styles.ChannelPageContent, {
        [cssTheme.Theme]: isRootPage
      })}
    >
      <div className={styles.ChannelPageContainer}>
        <ChannelPageContent
          isAdmin={isAdmin}
          channel={channel.data}
          viewAsUser={viewAsUser}
          onChangeView={handleShowAsUserClick}
          changedTab={changedTab}
        >
          {channelSlug && channelSlug === company && (
            <ChannelPageRoutes
              channelType={channelType}
              channelSlug={channelSlug}
              isScheduleAdmin={isScheduleAdmin}
              isAdmin={isAdmin}
              isViewingAsUser={viewAsUser}
              isRootPage={isRootPage}
              channel={channel}
              onChangeTab={handleChangeTab}
            />
          )}
        </ChannelPageContent>
      </div>
    </div>
  ) : channel.isError ? (
    <NotFoundPage />
  ) : null
}
