import React, { FC, useState } from 'react'
import { t } from '@lingui/macro'

import IconTextButton, {
  ButtonType
} from '../../../../components/buttons/TextIconButton/index'
import HeartIcon from '../../../../components/buttons/TextIconButton/GlobeHeartIcon'
import { FilledHeartIcon } from '../Images/FilledHeartIcon'
import {
  Breakpoint,
  useCurrentBreakpoint
} from '../../../../hooks/CurrentBreakpoint'

import classNames from 'classnames'
import useCurrentUser from 'hooks/CurrentUser'
import { useDispatch, useSelector } from 'react-redux'
import TagManager from 'react-gtm-module'
import authenticationActions from 'redux-store/authentication/authentication.actions'
import { channelSelector } from 'redux-store/channel/channel.selectors'
import { toggleFollow } from 'api/user/followChannel'
import styles from './FollowChannelButton.module.scss'

interface Props {
  hasTheme: boolean
}

export const FollowChannelButton: FC<Props> = ({ hasTheme }) => {
  const currentUser = useCurrentUser()
  const dispatch = useDispatch()
  const channel = useSelector(channelSelector).data

  const channelCountry = channel?.country?.name
  const channelType = channel?.type
  const channelName = channel?.name
  const channelUrl = channel?.channel_url
  const subdomain = channel?.subdomain
  const currentBreakpoint = useCurrentBreakpoint()
  const isMobile = currentBreakpoint <= Breakpoint.sm
  const sport = channel.category.translated_name || channel.category?.name
  const isFollowing =
    currentUser && currentUser.followed_companies !== null
      ? currentUser.followed_companies.includes(subdomain)
      : false

  const handleFollowClick = () => {
    if (!currentUser) {
      dispatch(authenticationActions.openLoginLayer())
      return false
    }
    if (!isFollowing) {
      TagManager?.dataLayer({
        dataLayer: {
          event: 'follow_clicked',
          value: subdomain,
          followed_channel_name: channelName,
          followed_sport: sport,
          followed_channel_country: channelCountry,
          followed_partner_type: channelType,
          followed_channel_url: channelUrl
        }
      })
    }
    return toggleFollow()
  }

  const following = t`Following`
  const follow = t`Follow`
  const unfollow = t`Unfollow`
  const label = isFollowing ? following : follow

  const [textToShow, setTextToShow] = useState(label)
  const size = currentBreakpoint > Breakpoint.sm ? 'Medium' : 'Small'
  const followClick = () => {
    handleFollowClick()
    switch (textToShow) {
      case follow:
        setTextToShow(following)
        break
      case unfollow:
        setTextToShow(follow)
        break
      case following:
        setTextToShow(follow)
        break
    }
  }

  const onMouseEnter = () => {
    if (label === following) setTextToShow(unfollow)
  }
  const onMouseLeave = () => {
    setTextToShow(label)
  }
  return (
    <div data-testid="channel-follow-button" className={styles.FollowButton}>
      <IconTextButton
        id={`${channelName}-follow-button`}
        type={hasTheme ? ButtonType.ThemedInverted : ButtonType.Ghost}
        buttonSize={size}
        label={textToShow}
        onClick={followClick}
        onMouseEnter={isMobile ? () => null : onMouseEnter}
        onMouseLeave={isMobile ? () => null : onMouseLeave}
      >
        {isFollowing ? (
          <div
            className={classNames({
              [styles.FilledHeartIcon]: !hasTheme,
              [styles.ThemedFilledHeart]: hasTheme,
              [styles.ThemedFilledHeartOnHover]:
                textToShow === unfollow && hasTheme
            })}
          >
            <FilledHeartIcon />
          </div>
        ) : (
          <HeartIcon />
        )}
      </IconTextButton>
    </div>
  )
}
