import React, { FC, ReactNode } from 'react'
import styles from './RoundButton.module.scss'
import classNames from 'classnames'
interface Props {
  id: string
  onClick: () => void
  children?: ReactNode
  externalStyle?: string | null
}

export const RoundButton: FC<Props> = ({
  id,
  onClick,
  children,
  externalStyle
}) => {
  return (
    <div
      aria-label={`share on ${id}`}
      data-testid={`round-button-${id}`}
      className={classNames(styles.RoundButton, externalStyle)}
      tabIndex={0}
      role="button"
      onClick={onClick}
    >
      <div className={styles.Icon}>{children}</div>
    </div>
  )
}
