import React, { FC } from 'react'
import { t, Trans } from '@lingui/macro'
import styles from './ShareComponent.module.scss'
import { ShareOnFacebookButton } from './ShareOnFacebookButton'
import { getFacebookLoginId } from '../../../session/getIsLoginAvailable'
import { CopyLinkField } from 'components/Forms/CopyLinkField/CopyLinkField'

import { TwitterIcon } from 'components/page/footer/themedFooter/topSection/socialMedia/TwitterIcon'
import { RoundButton } from 'components/buttons/RoundButton/RoundButton'
import { FacebookIcon } from 'components/page/footer/themedFooter/topSection/socialMedia/FacebookIcon'
import { EmailIcon } from 'assets/icons/EmailIcon'
import { IFrameSection } from './IframeSection'

enum ShareOptionType {
  Twitter = 'Twitter',
  Facebook = 'Facebook',
  Email = 'Email'
}

interface ShareComponentProps {
  mediaId: string
  mediaTitle: string
  companySlug: string
  onClose: () => void
}

type ShareItemType = {
  id: ShareOptionType
  name: string
  url?: string
}

export const ShareComponent: FC<ShareComponentProps> = ({
  mediaId,
  mediaTitle,
  onClose,
  companySlug
}) => {
  const shareList = [
    {
      id: ShareOptionType.Facebook,
      name: 'Facebook',
      icon: <FacebookIcon />
    },
    {
      id: ShareOptionType.Twitter,
      name: 'Twitter',
      url: `https://twitter.com/intent/tweet?url=${mediaTitle} ${window.location.href}`,
      icon: <TwitterIcon />
    },
    { id: ShareOptionType.Email, name: t`Email`, icon: <EmailIcon /> }
  ]

  const handleShareClick = (shareType: ShareItemType) => {
    if (shareType.id === ShareOptionType.Twitter) {
      window.open(shareType.url, '_blank')
    }
    if (shareType.id === ShareOptionType.Email) {
      window.open(`mailto:?subject=${mediaTitle}&body=${window.location.href}`)
    }
    onClose()
  }
  const facebookId = getFacebookLoginId()

  return (
    <div
      className={styles.ShareComponentContainer}
      data-testid="share-component-container"
    >
      <div className={styles.MediaTitle}>{mediaTitle}</div>
      <div
        className={styles.ShareComponentSection}
        data-testid="share-social-media"
      >
        <div className={styles.Heading}>
          <Trans>Share with</Trans>
        </div>
        <div className={styles.shareListSection} data-testid="share-buttons">
          {shareList.map((shareItem) => {
            if (shareItem.id === ShareOptionType.Facebook && facebookId) {
              return (
                <ShareOnFacebookButton
                  onClose={onClose}
                  key={shareItem.name}
                  id={shareItem.id}
                  facebookId={facebookId}
                  facebookIcon={shareItem.icon}
                />
              )
            }
            return (
              <RoundButton
                key={shareItem.name}
                onClick={() => handleShareClick(shareItem)}
                id={shareItem.id.toString()}
                externalStyle={styles.RoundButton}
              >
                {shareItem.icon}
              </RoundButton>
            )
          })}
        </div>
      </div>
      <div className={styles.ShareComponentSection} data-testid="share-link">
        <CopyLinkField
          label={t`Or copy link`}
          textValue={window.location.href}
        />
      </div>
      <IFrameSection companySlug={companySlug} mediaId={mediaId} />
    </div>
  )
}
