import React from 'react'

export const EmailIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22 10.6032V17.5873C22 17.9577 21.8084 18.313 21.4675 18.575C21.1265 18.8369 20.664 18.9841 20.1818 18.9841H3.81818M2 13.7659L2 6.41269C2 6.04223 2.19156 5.68694 2.53253 5.42499C2.87351 5.16303 3.33597 5.01587 3.81818 5.01587H6.54545"
      stroke="#2F3135"
      strokeWidth="1.36364"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22 10.6032V6.50581C22 6.11066 21.7932 5.73168 21.4252 5.45226C21.0572 5.17284 20.558 5.01587 20.0375 5.01587H4.85714"
      stroke="#2F3135"
      strokeWidth="1.36364"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.4285 5.01587L11.9999 13.3968L20.5714 5.01587"
      stroke="#2F3135"
      strokeWidth="1.36364"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
