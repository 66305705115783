import React, { FC, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { ShareComponent } from './ShareComponent/ShareComponent'
import { isMobile } from 'react-device-detect'
import { BasicModal, ModalTheme } from '../BasicModal/BasicModal'
import styles from './ShareModal.module.scss'

interface ShareModal {
  defaultOpen: boolean
  companySlug: string
  onClose: () => void
  mediaId: string
  mediaTitle: string
}

export const ShareModal: FC<ShareModal> = ({
  defaultOpen,
  companySlug,
  onClose,
  mediaId,
  mediaTitle
}) => {
  const location = useLocation()
  const [isModalRequired, setIsModalRequired] = useState(false)

  const openMobileShare = async () => {
    try {
      await navigator.share({
        title: mediaTitle,
        text: mediaTitle,
        url: location.pathname
      })
    } catch (err: any) {
      console.log(err)
    }
  }

  useEffect(() => {
    if ((window as any).navigator?.share && isMobile) {
      openMobileShare()
      onClose()
    } else {
      setIsModalRequired(true)
    }
  }, [location.pathname, mediaId, mediaTitle])

  return (
    <BasicModal
      isOpen={defaultOpen && isModalRequired}
      firstContainerTheme={ModalTheme.Primary}
      firstContainerContent={
        <ShareComponent
          mediaId={mediaId}
          mediaTitle={mediaTitle}
          onClose={onClose}
          companySlug={companySlug}
        />
      }
      customStyle={styles.ShareModal}
      handleClose={onClose}
    />
  )
}
