import React, { FC, ReactNode, useCallback, useEffect, useRef } from 'react'
import useExternalScript from '../../../../hooks/ExternalScript'
import { RoundButton } from 'components/buttons/RoundButton/RoundButton'
import styles from './ShareComponent.module.scss'
interface ShareOnFacebookButtonProps {
  onClose: () => void
  facebookId: string
  id: string
  facebookIcon: ReactNode
}

export const ShareOnFacebookButton: FC<ShareOnFacebookButtonProps> = ({
  onClose,
  id,
  facebookId,
  facebookIcon
}) => {
  const facebookLoadStatus = useExternalScript(
    'https://connect.facebook.net/en_US/all.js'
  )
  const fbElementRef = useRef<HTMLDivElement>(null)

  const onFacebookShare = useCallback(() => {
    ;(window as any).FB.ui(
      {
        display: 'popup',
        method: 'share',
        href: `${window.location.href}`
      },
      () => {
        onClose()
      }
    )
  }, [(window as any).FB])

  useEffect(() => {
    if (facebookLoadStatus.code === 200) {
      ;(window as any).FB?.init({
        appId: facebookId,
        status: true,
        xfbml: true,
        version: 'v11.0'
      })
    }
  }, [facebookLoadStatus.code, facebookId])

  return (
    <div ref={fbElementRef}>
      <RoundButton
        id={id}
        onClick={onFacebookShare}
        externalStyle={styles.RoundButton}
      >
        {facebookIcon}
      </RoundButton>
    </div>
  )
}
