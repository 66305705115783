import React, { FC } from 'react'
import { t } from '@lingui/macro'

import IconTextButton, {
  ButtonType
} from '../../../../components/buttons/TextIconButton/index'
import EyeIcon from '../Images/EyeIcon'

import styles from './ShowAsUserButton.module.scss'

interface Props {
  id: string
  isViewingAsUser: boolean
  onClick: (id: string) => void
}

export const ShowAsUserButton: FC<Props> = ({
  id,
  isViewingAsUser,
  onClick
}) => {
  const toggleDisplay = (channelId: string) => {
    onClick(channelId)
  }
  const label = isViewingAsUser ? t`Show as admin` : t`Show as user`

  return (
    <div data-testid="show-as-user-button" className={styles.ShowAsUserButton}>
      <IconTextButton
        id={id}
        type={ButtonType.GhostInverted}
        buttonSize="Small"
        label={label}
        onClick={() => toggleDisplay(id)}
        isRounded
      >
        <EyeIcon />
      </IconTextButton>
    </div>
  )
}
