import React, { FC } from 'react'
import classNames from 'classnames'

import ChannelName from './ChannelName/ChannelName'
import HeroImage from './HeroImage'
import { ChannelLogo } from './ChannelLogo/ChannelLogo'
import { FollowChannelButton } from './FollowChannelButton/FollowChannelButton'
import Submenu from './Submenu/Submenu'

import { ShowAsUserButton } from './ShowAsUserButton/ShowAsUserButton'
import AdminPanel from './AdminPanel/AdminPanel'
import { ChannelPageTitle } from './ChannelPageTitle/ChannelPageTitle'
import { ChannelTypes } from '../channelType'
import { useLocation } from 'react-router-dom'
import { ChannelMenuLink } from './menuLinks'
import { ChannelInfoSection } from './ChannelInfoSection/ChannelInfoSection'
import styles from './Header.module.scss'
import { useSelector } from 'react-redux'
import { channelSelector } from 'redux-store/channel/channel.selectors'

interface Props {
  isAdmin: boolean
  isViewingAsUser: boolean
  onChangeView: () => void
  onSelectSubmenu: (id: string) => void
  selectedSubmenu: string
  channelSubmenuLinks: ChannelMenuLink[]
}

export const Header: FC<Props> = ({
  isAdmin,
  isViewingAsUser,
  onChangeView,
  onSelectSubmenu,
  selectedSubmenu,
  channelSubmenuLinks
}) => {
  const channel = useSelector(channelSelector).data
  const {
    ident: channelId,
    type: channelType,
    isRootChannel: isRootPage,
    promotional_image_url: heroImageUrl,
    logo_image_url: logoImageUrl,
    custom_content: customContent,
    root_domain: rootDomain,
    subdomain: channelSlug,
    name: channelName,
    category
  } = channel

  const sport = category.translated_name || category?.name

  const location = useLocation()
  const urlpath = location.pathname
  const isNotStartPage = channelSubmenuLinks.some(
    (link) => link.link !== '/' && urlpath.includes(link.link.toString())
  )

  const showHeroImage = !isNotStartPage
  const showAdminPanel = showHeroImage && isAdmin
  const showChannelInfoSection = !showHeroImage && !isRootPage
  const showSubmenuTopBorder =
    ![ChannelTypes.club, ChannelTypes.team].includes(channelType) &&
    !showHeroImage &&
    isRootPage

  return (
    <header className={styles.HeaderContainer}>
      <ChannelPageTitle
        selectedSubmenu={selectedSubmenu}
        customContent={customContent}
        rootDomain={rootDomain}
        sport={sport}
        channelSlug={channelSlug}
      />
      {showHeroImage && (
        <div
          className={classNames({
            [styles.HeaderRootContentContainer]: isRootPage,
            [styles.HeaderContentContainer]: !isRootPage
          })}
        >
          <div
            className={styles.ImagesContainer}
            data-testid="images-container"
          >
            <HeroImage
              isRootPage={isRootPage}
              imageUrl={heroImageUrl}
              canChangeImage={isAdmin && !isViewingAsUser}
              channelSlug={channelSlug}
            />
            {showAdminPanel && (
              <ShowAsUserButton
                id={channelId}
                isViewingAsUser={isViewingAsUser}
                onClick={onChangeView}
              />
            )}
            {!isRootPage && (
              <div className={styles.HeaderLogoImage}>
                <ChannelLogo
                  imageUrl={logoImageUrl}
                  canChangeImage={isAdmin && !isViewingAsUser}
                  channelSlug={channelSlug}
                />
              </div>
            )}
            <div className={styles.FollowButtonContainer}>
              <FollowChannelButton hasTheme={isRootPage} />
            </div>
          </div>
          <ChannelName
            isRootPage={isRootPage}
            channelName={channelName}
            channelType={channelType}
          />
        </div>
      )}
      {showChannelInfoSection && <ChannelInfoSection />}
      {showAdminPanel && !isViewingAsUser && (
        <div className={styles.HeaderAdminPanel}>
          <AdminPanel channelSlug={channelSlug} />
        </div>
      )}
      <div
        className={classNames(styles.SubmenuContainer, {
          [styles.WithTopBorder]: showSubmenuTopBorder
        })}
      >
        <Submenu
          channelType={channelType}
          channelSlug={channelSlug}
          onSelected={onSelectSubmenu}
          selected={selectedSubmenu}
          isRootPage={isRootPage}
          menuLinks={channelSubmenuLinks}
          isNotStartPage={isNotStartPage}
        />
      </div>
    </header>
  )
}
