import React, { FC, useEffect, useState } from 'react'

import { Honeybadger } from '@honeybadger-io/react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'

import { Company } from '../../interfaces/Company.interface'
import DefaultError from '../../components/app/DefaultError'
import { WebpageMeta } from '../../meta/WebpageMeta'
import { Header } from './Header/Header'
import { links } from './Header/menuLinks'
import { getPathList } from './Header/Breadcrumb/getPathList'
import { useTranslate } from 'hooks/Translate'
import { channelCustomPageSelector } from 'redux-store/channel/channel.selectors'
import { getCustomSubmenuItem } from './Header/Submenu/getCustomSubmenuItem'
import { getSubmenuLinksForChannel } from './getSubmenuLinksForChannel'
import styles from './channel.module.scss'

interface Props {
  // eslint-disable-next-line react/require-default-props
  channel?: Company | undefined
  isAdmin: boolean
  viewAsUser: boolean
  onChangeView: () => void
  changedTab: string
  children?: React.ReactNode
}

const ChannelPageContent: FC<Props> = ({
  channel,
  children,
  isAdmin,
  viewAsUser,
  onChangeView,
  changedTab
}) => {
  const location = useLocation()
  const translate = useTranslate()

  const { pathname } = location
  const split = pathname.split('/')
  const currentPathname = split.length > 2 && split[2]
  const currentPathNameCustomPage = split.length > 2 && `menu/${split[3]}`
  const custompages = useSelector(channelCustomPageSelector)
  const impressumLink = channel?.impressum_page?.slug
  const customPagesSubmenu = getCustomSubmenuItem(custompages, impressumLink)
  const linksToUse = getSubmenuLinksForChannel(links, customPagesSubmenu)
  const urlpath = location.pathname
  const isSearchPage = urlpath.includes('search')
  const selectedLink = linksToUse.find(
    (link) =>
      `/${currentPathname}` === link.link ||
      `/${currentPathNameCustomPage}` === link.link
  )

  const [selectedMenu, setSelectedMenu] = useState(
    !selectedLink ? '1' : selectedLink.id
  )
  useEffect(() => {
    if (selectedLink && selectedLink.id !== selectedMenu) {
      setSelectedMenu(selectedLink?.id)
    }
  }, [changedTab, currentPathname])

  let PageMetaData = <></>
  try {
    const breadcrumbs = getPathList(
      // @ts-ignore
      channel?.parentChannelSubdomain,
      channel?.subdomain,
      channel?.name,
      // @ts-ignore
      channel?.isRootChannel,
      location,
      translate
    )
    PageMetaData = channel ? (
      <WebpageMeta channel={channel} breadcrumbs={breadcrumbs} />
    ) : (
      <></>
    )
  } catch (e) {
    console.log('Could not set headerData in MainContent', e)
  }
  const errorHandler = (error: Error, info: { componentStack: string }) => {
    Honeybadger.notify(error)
    console.log('channnel page errorHandler: ', error, '\ninfo:', info)
  }
  if (!channel) return null

  const channelDetails: Company = channel
  const {
    // @ts-ignore
    isRootChannel
  } = channelDetails

  const handleSelectSubmenu = (id: string) => {
    setSelectedMenu(id)
  }

  const hideHeader = isSearchPage && isRootChannel
  return (
    <>
      {PageMetaData}
      {hideHeader ? null : (
        <Header
          isAdmin={isAdmin}
          isViewingAsUser={viewAsUser}
          onChangeView={onChangeView}
          selectedSubmenu={selectedMenu}
          channelSubmenuLinks={linksToUse}
          onSelectSubmenu={(id: string) => handleSelectSubmenu(id)}
        />
      )}

      <ErrorBoundary
        FallbackComponent={DefaultError}
        onError={errorHandler}
        onReset={() => {
          console.log('error boundary error!')
        }}
      >
        <section className={styles.Section}>{children}</section>
      </ErrorBoundary>
    </>
  )
}
export default ChannelPageContent
