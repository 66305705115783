import React, { FC, useEffect, useState } from 'react'
import styles from './ShareComponent.module.scss'
import { OpenCloseArrow } from 'components/OpenCloseArrow/OpenCloseArrow'
import { CopyLinkField } from 'components/Forms/CopyLinkField/CopyLinkField'
import { t } from '@lingui/macro'
import useAdminAccess from 'hooks/AdminAccess'

interface Props {
  companySlug: string
  mediaId: string
}

export const IFrameSection: FC<Props> = ({ companySlug, mediaId }) => {
  const [showAdvanced, setShowAdvanced] = useState(false)
  const [iFrameSrc, setIFrameSrc] = useState('')
  const adminAccess = useAdminAccess()
  const isChannelAdmin = adminAccess.hasChannelAccess

  useEffect(() => {
    const iframeSrc = `${process.env.REACT_APP_PROTOCOLL}${process.env.REACT_APP_SERVER_URL}/${companySlug}/widgets/thumbnail/${mediaId}`
    setIFrameSrc(
      `<iframe src="${iframeSrc}" width="560" height="315" frameborder="0" scrolling="0" allowfullscreen></iframe>`
    )
  }, [mediaId, companySlug, showAdvanced])

  return isChannelAdmin ? (
    <div className={styles.AdvancedSection}>
      <div
        className={styles.AdvancedButton}
        role="button"
        onClick={() => setShowAdvanced(!showAdvanced)}
      >
        <div className={styles.AdvancedHeading}>Advanced</div>
        <OpenCloseArrow isOpen={showAdvanced} />
      </div>
      {showAdvanced && (
        <div
          className={styles.ShareComponentSection}
          data-testid="share-embedded-link"
        >
          <div data-testid="share-iframe-link">
            <CopyLinkField label={t`Embeded link`} textValue={iFrameSrc} />
          </div>
        </div>
      )}
    </div>
  ) : null
}
